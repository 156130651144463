
// Vuex
import { mapGetters } from 'vuex';

// Constants
import { navigations } from 'portal/assets/js/constants/menuItemsFooter';

export default {
    name: 'NavigationsBlockFooter',

    data() {
        return {
            navigations,
        };
    },

    computed: {
        ...mapGetters({
            isTestStage: 'domain/getIsTestStage',
        }),
    },

    methods: {
        getIsMenuItemActive(item) {
            return this.$route.path.split('/')?.[1] === item.to?.slice(1);
        },

        getIsMenuItemDisabled(item) {
            return item.disabled || item.onlyTest && !this.isTestStage;
        },

        setLinkComponent(item) {
            return item.to ? 'nuxt-link' : 'a';
        },

        setLinkAttrs(item) {
            return item.to
                ? {
                    to: item.to,
                }
                : {
                    href: item.href,
                    target: '_blank',
                };
        },

        setGridPosition(item) {
            return {
                gridRow: item.row,
                gridColumn: item.column,
            };
        },
    },
};
